import * as React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { PropsWithChildren, Suspense } from "react";
import CenterSpinner from "../sharedComponents/CenterSpinner";
import { useIsRestoring } from "@tanstack/react-query";
import useAuth from "../store/auth/authState";

const Login = React.lazy(() => import("./auth/components/Login.page"));

const CategoryList = React.lazy(
  () => import("./categories/components/CategoriesGrid.page"),
);

const Main = React.lazy(() => import("./main/components/Main.page"));

const GuardedRoute = ({ children }: PropsWithChildren) => {
  const { tokens } = useAuth();
  const isRestoring = useIsRestoring();
  if (isRestoring) return <CenterSpinner />;
  if (!tokens.accessToken) return <Navigate to="/auth/sign-in" replace />;
  return <>{children}</>;
};

const Router = () => {
  return (
    <Suspense>
      <Routes>
        <Route path="/auth/sign-in" element={<Login />} />

        <Route
          path="/*"
          element={
            <GuardedRoute>
              <CategoryList />
            </GuardedRoute>
          }
        >
        </Route>
      </Routes>
    </Suspense>
  );
};

export default Router;
