import React from "react";
import {
  Box,
  Flex,
  Image,
  useMediaQuery,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import Logo from "../assets/images/EYA_logo.svg";

const Header: React.FC = () => {
  const [isMobileDevice] = useMediaQuery("(min-width: 768px)");

  const ml = isMobileDevice ? "8vw" : "5vw";

  return (
    <Flex
      as="header"
      alignItems="center"
      fontFamily="Poppins Polly, sans-serif"
    >
      <Box ml={ml}>
        <Link to="/">
          <Flex align="center" justifyContent="center">
            <Image
              boxSize="110px"
              src={Logo}
              alt="EYA Logo"
            />
          </Flex>
        </Link>
      </Box>
    </Flex>
  );
};

export default Header;
